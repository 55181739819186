import getCookie from "./getCookie";
import axios from "axios";

// ISO 3166-1-alpha-2 country codes
const countries = Object.fromEntries(require("./countries.json").map(i => ([i["Code"].toUpperCase(), i["Name"]])));
let country = null;

const getCountry = () => {
    if (country !== null) {
        return country;
    }
    // try cookie
    const code = getCookie("cloudfront-view-country");
    if (code && code.toUpperCase() in countries) {
        country = code.toUpperCase();
        return country;
    }

    // try ajax async
    axios.head("https://www.utorrent.com")
        .then(response => {
            const code = response.headers["cloudfront-viewer-country"];
            if (code && code.toUpperCase() in countries) {
                country = code.toUpperCase();
            } else {
                country = "US"
            }
        })
        .catch(_ => country = "US");
    
    return "US";
};

export { getCountry };