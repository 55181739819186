import React, {useEffect, useState} from 'react';
import loadable from '@loadable/component'
import Navigation from "./navigation"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import AndroidBanner from "./androidBanner"
import CompatibilityPopup from "../components/compatibilityPopup"
import { unlocalizedPageCheck } from "../utils/unlocalizedPageCheck"
import faviconNew from "../images/faviconUT.ico"
import { getShowPromoBanner, PromoBanner } from "../components/promo"
import { getCountry } from "../utils/country"
import LiteBanner from './LiteBanner'

const Footer = loadable(() => import('./footer'));

const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale, unlocalizedPath, pageName, navText, meta, footerText, compatibilityText, androidBannerText} }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
      topOnEmptyHash:false,
      header:'.navbar'
    })
  }
  const { data } = useStaticQuery(query)
  const siteUrl = data.siteMetadata.siteUrl
  let localeStr = locale.replace('_','-')
  const ogUrl = (locale === 'en') ? siteUrl+unlocalizedPath : `${siteUrl}/${localeStr}${unlocalizedPath}`
  const description = stripHTML(meta.description)
  const socialImg = (meta.socialImgUrl) ? 'https://wwww.utorrent.com/social/'+ meta.socialImgUrl : 'https://wwww.utorrent.com/social/og-utorrent.png'

  const isHomepage = pageName === "index"
  const isBTWebFree = pageName === "web"
  const isBTWebFreeMac = pageName === "web_mac"
  const isBTWebPrem = pageName === "web_pro"
  const isClassFree = pageName === "desktop"
  const isClassPrem = pageName === "pro"
  const isAndro = pageName === "mobile"

  const homepageStructuredData = <>
    <script type='application/ld+json'>
      {`{
        "@context": "https://schema.org",
        "@type": "Corporation",
        "name": "BitTorrent, Inc.",
        "url": "https://www.utorrent.com",
        "logo": "https://cdn-assets.bittorrent.com/knowledge-panel/uTorrent_Logo.svg",
        "sameAs": [
          "https://www.facebook.com/utorrent",
          "https://twitter.com/utorrent",
          "https://www.instagram.com/utorrent/",
          "https://www.youtube.com/channel/UCzYgPFi_9RqlZI6I7YQDh_Q"
        ]
      }`}
    </script>
  </>

  const btwebfreeStructuredData = <>
    <script type='application/ld+json'>
      {`{  
        "@context" : "http://schema.org",  
        "@type" : "SoftwareApplication",  
        "name" : "uTorrent Web",  
        "url" : "https://www.utorrent.com/web",  
        "publisher" : 
        {    
          "@type" : "Organization",    
          "name" : "BitTorrent, Inc."  
        },  
        "downloadUrl" : "https://www.utorrent.com/web/downloads/complete/track/stable/os/win/",  
        "operatingSystem" : "WINDOWS",  
        "applicationCategory": "https://schema.org/WebApplication",  
        "offers": 
        {   
           "@type": "Offer",    
           "price": "0",    
           "priceCurrency": "USD"  
        }
      }`}
    </script>
  </>

  const btwebfreemacStructuredData = <>
    <script type='application/ld+json'>
      {`{  
        "@context" : "http://schema.org",  
        "@type" : "SoftwareApplication",  
        "name" : "uTorrent Web for Mac",  
        "url" : "https://www.utorrent.com/web/mac",  
        "publisher" : 
        {    
          "@type" : "Organization",    
          "name" : "BitTorrent, Inc."  
        },  
        "downloadUrl" : "https://www.utorrent.com/web/downloads/complete/track/stable/os/mac/",  
        "operatingSystem" : "MAC",  
        "applicationCategory": "https://schema.org/WebApplication",
        "offers": 
        {   
           "@type": "Offer",    
           "price": "0",    
           "priceCurrency": "USD"  
        }
      }`}
    </script>
  </>

  const btwebpremStructuredData = <>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"uTorrent Web Pro",
         "url":"https://www.utorrent.com/web/pro/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://gateway.lavasoft.com/utorrentpro/cart/",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/WebApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"19.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"uTorrent Web Pro+VPN",
         "url":"https://www.utorrent.com/web/pro/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://gateway.lavasoft.com/utorrentpro-vpn/cart/",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/WebApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"69.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  const classicfreeStructuredData = <>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"uTorrent Classic",
         "url":"https://www.utorrent.com/desktop/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://www.utorrent.com/downloads/complete/track/stable/os/win/",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"0",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  const classicpremStructuredData = <>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"uTorrent Classic Ad Free",
         "url":"https://www.utorrent.com/products/pro/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://store.utorrent.com/849/purl-utafwebw",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"4.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{ 
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"uTorrent Classic Pro",
         "url":"https://www.utorrent.com/pro/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://store.utorrent.com/849/purl-utproweb",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"19.95",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{
         "@context":"http://schema.org",
         "@type":"SoftwareApplication",
         "name":"uTorrent Classic Pro+VPN",
         "url":"https://www.utorrent.com/pro/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://store.utorrent.com/849/purl-utwebprovpn6995",
         "operatingSystem":"WINDOWS",
         "applicationCategory":"https://schema.org/SoftwareApplication",
         "offers":{ 
            "@type":"Offer",
            "price":"69.95",
            "priceCurrency":"USD"
         }
       }`}
    </script>
  </>

  const androidStructuredData = <>
    <script type='application/ld+json'>
      {`{  
         "@context":"http://schema.org",
         "@type":"MobileApplication",
         "name":"uTorrent Android",
         "url":"https://www.utorrent.com/mobile/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://play.google.com/store/apps/details?id=com.utorrent.client",
         "operatingSystem":"ANDROID",
         "applicationCategory":"https://schema.org/MobileApplication",
         "aggregateRating":{ 
            "@type":"AggregateRating",
            "ratingValue":"4.6",
            "ratingCount":"776188"
         },
         "offers":{ 
            "@type":"Offer",
            "price":"0",
            "priceCurrency":"USD"
         }
      }`}
    </script>
    <script type='application/ld+json'>
      {`{  
         "@context":"http://schema.org",
         "@type":"MobileApplication",
         "name":"uTorrent Android Pro",
         "url":"https://www.utorrent.com/mobile/",
         "publisher":{ 
            "@type":"Organization",
            "name":"BitTorrent, Inc."
         },
         "downloadUrl":"https://play.google.com/store/apps/details?id=com.utorrent.client.pro",
         "operatingSystem":"ANDROID",
         "applicationCategory":"https://schema.org/MobileApplication",
         "aggregateRating":{ 
            "@type":"AggregateRating",
            "ratingValue":"4.4",
            "ratingCount":"8069"
         },
         "offers":{ 
            "@type":"Offer",
            "price":"2.99",
            "priceCurrency":"USD"
         }
      }`}
    </script>
  </>

  let isUnlocalizedPage = unlocalizedPageCheck(unlocalizedPath)

  const hrefLangs = <>
    <link rel="alternate" hrefLang="de" href={`${siteUrl}/intl/de${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="es" href={`${siteUrl}/intl/es${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="fr" href={`${siteUrl}/intl/fr${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="hi" href={`${siteUrl}/intl/hi${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="it" href={`${siteUrl}/intl/it${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="ja" href={`${siteUrl}/intl/ja${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="ko" href={`${siteUrl}/intl/ko${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="nl" href={`${siteUrl}/intl/nl${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="pl" href={`${siteUrl}/intl/pl${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="pt" href={`${siteUrl}/intl/pt${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="pt-br" href={`${siteUrl}/intl/pt-br${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="ru" href={`${siteUrl}/intl/ru${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="tl" href={`${siteUrl}/intl/tl${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="tr" href={`${siteUrl}/intl/tr${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="zh-cn" href={`${siteUrl}/intl/zh-cn${unlocalizedPath}`} />
    <link rel="alternate" hrefLang="zh-tw" href={`${siteUrl}/intl/zh-tw${unlocalizedPath}`} />
  </> 

  const isAndroid = typeof window !== 'undefined' ? window.gatsbyIsAndroid : false;
  const isMobile = typeof window !== 'undefined' ? window.gatsbyIsMobile : false;
  const isIpad = typeof window !== 'undefined' ? window.gatsbyIsIpad : false;
  const isCrOS = typeof window !== 'undefined' ? window.gatsbyIsCrOS : false;
  const isiOS = typeof window !== 'undefined' ? window.gatsbyIsiOS : false;
  const countryCode = getCountry();
  const [banner, setBanner] = useState(null)  // "lite" | "android" | "promotion"

  useEffect(() => {
    if(getShowPromoBanner()) {
      return setBanner("promotion");
    }
    if (!isHomepage) {
      return setBanner("lite");
    }
    return setBanner(null);
  }, [pageName, countryCode, isIpad, isiOS, isAndroid, isCrOS, isMobile])

  const getBanner = (type) => {
    switch(type) {
      case "lite": return <LiteBanner navText={navText} locale={locale} />
      case "android": return <AndroidBanner locale={locale} text={androidBannerText} />
      case "promotion": return <PromoBanner />
    }
  }

  return (
  <LocaleContext.Provider value={{ locale }}>
    <Helmet>
          <html lang={locale}/>
          <title>{meta.title}</title>
          <meta http-equiv="content-type" content="text/html; charset=UTF-8"/>
          <meta name="viewport" content="width=device-width"/>
          <meta name="description" content={description}/>
          <meta name="keywords" content={meta.keywords}/>
          <meta name="author" content="BitTorrent, Inc."/>
          <meta property="og:url" content={ogUrl}/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={meta.title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:image" content={socialImg}/>
          <meta property="og:site_name" content="uTorrent"/>
          <meta name="twitter:site" content="@uTorrent" />
          <meta name="twitter:image:src" content={socialImg} />
          <meta name="twitter:card" content="summary_large_image"/>
          <meta http-equiv="content-language" content={ locale }/>
          <meta name="norton-safeweb-site-verification" content="qgl9948fufueg1yeoo91b08gvj502akd1jmfxfaqtesmkwkw3c25b2yc1tf0c8rvxqf3znm-hgcnmd3njs67vr9hhf3n71f25jlyje4elcn-8jx9r18y7ccp3o5zbfhm" />
          <link href={faviconNew} rel="shortcut icon"/>
          <link rel="alternate" hrefLang="en" href={`${siteUrl}${unlocalizedPath}`} />
          <link rel="alternate" hrefLang="x-default" href={`${siteUrl}${unlocalizedPath}`} />

          {isHomepage && homepageStructuredData.props.children}
          {isBTWebFree && btwebfreeStructuredData.props.children}
          {isBTWebFreeMac && btwebfreemacStructuredData.props.children}
          {isBTWebPrem && btwebpremStructuredData.props.children}
          {isClassFree && classicfreeStructuredData.props.children}
          {isClassPrem && classicpremStructuredData.props.children}
          {isAndro && androidStructuredData.props.children}

          {!isUnlocalizedPage && hrefLangs.props.children}
          {/* declaring hrefLangs.props.children is a workaround to use fragment in helmet*/}
          
          <link rel="canonical" href={ogUrl} />
          <link href="/qne4zsu.css" rel="stylesheet" media="print" onload="this.media='all'" />
          <link href="/fonts/Inter/inter.css" rel="stylesheet" media="print" onLoad="this.media='all'"></link>
    </Helmet>
    <div className={`container-fluid px-0 mx-0`}>
      <CompatibilityPopup pageContext={{"text":compatibilityText, "locale":locale}}>
        <header className="global-header">
          {
            banner !== null && getBanner(banner)
          }
          <Navigation pageContext={{ "unlocalizedPath": unlocalizedPath, "navText":navText}}/>
        </header>
        
        <main className={`container-fluid shiroi px-0 ${banner !== null ? 'hasBanner':''} ${isAndroid ? 'isAndroid':''}`}>

              {children}

        </main>
      </CompatibilityPopup>
        <Footer pageContext={{ "footerText":footerText, unlocalizedPath, locale }} />
    </div>
  </LocaleContext.Provider>
)}

export { Layout, LocaleContext }

const query = graphql`
query layout {
    data: site {
        siteMetadata {
          siteUrl
        }
      }
  }
`

function stripHTML(string){
  return string.replace(/<(.|\n)*?>/g, '');
}

