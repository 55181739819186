import axios from "axios";

class BenchClient {
  constructor(iValue, serverEndpoint) {
    this.serverEndpoint = serverEndpoint;
    this.iValue = iValue;
  }

  ping(action) {
    const data = {
      eventName: 'utwebsite',
      action
    };
    return axios.get(`${this.serverEndpoint}/e?i=${this.iValue}&e=${btoa(JSON.stringify(data))}`);
  }
}

const BENCH_CLIENT = new BenchClient(3100, "https://utbench.utorrent.com");

export default BENCH_CLIENT;
